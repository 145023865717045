import styled from 'styled-components';

const Dot = styled.span`
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
    border: 2px solid white;
  background-color: ${({ color }) => color};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 13px;
  margin: 30px 0 10px;
  flex-wrap: wrap;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const maps = {
  crm: {
    text: 'Author sent to HubSpot',
    color: 'rgba(158, 255, 201, 0.42)'
  },
  podium: {
    text: 'Podium Author',
    color: 'rgba(255, 245, 157, 0.42)'
  },
  price: {
    text: 'Price equals 0',
    color: 'rgba(255, 157, 157, 0.36)'
  }
};

enum EMap {
  'crm',
  'podium',
  'price'
}

type TProps = {
  map?: (keyof typeof EMap)[]
};

const Legend = ({ map }: TProps) => (
  <Wrapper>
    {Boolean(map?.length) && map?.map(el => (<Item key={el}>
      <Dot color={maps[el].color}/>
      {maps[el].text}
    </Item>))}
  </Wrapper>
);

export default Legend;