import React from 'react';
import { Card } from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import './styles.css';
import { IGetBookSearch } from '../../core/modules/book/book.model';

interface IOptionCardProps {
  book: IGetBookSearch;
  paths: { label: string; url: string }[];
}

const OptionCard: React.FC<IOptionCardProps> = ({ book, paths }) => (
  <div className='option-wrapper'>
    <Card className='option-card'>
      <Meta
        avatar={
          book.coverimageurl ? (
            <img
              src={book.coverimageurl}
              alt={book.Title}
              loading='lazy'
              className='option-img'
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.style.display = 'none';
              }}
            />
          ) : null
        }
        title={
          <div className='option-text-wrap'>
            <div className='option-author'>
              {book.Author}
            </div>
            <div className='option-title-wrap'>{book.Title}</div>
          </div>
        }
        description={<div className='option-series-wrap'>{book.Series}</div>}
      />
      <div className='tab-tags-container'>
        {paths.length > 0 && (
          <div className='tab-links'>
            {paths.map(({ label, url }, index) => (
              <React.Fragment key={`${book.ASIN}-${label}`}>
                <a
                  href={url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='option-link'
                >
                  {label} <SelectOutlined />
                </a>
                {index < paths.length - 1 && ' | '}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </Card>
  </div>
);

export default React.memo(OptionCard);