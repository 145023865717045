/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useMemo, useState } from 'react';

const defaultUser = {
  id: null,
  edit_titles: false,
  role: 'Other',
  move_titles_hubspot: false,
  view_tables: false,
  default_filters: '',
  searchData: []
};

const UserContext = React.createContext({
  ...defaultUser,
  // eslint-disable-next-line no-empty-function
  updateSearchData: (newSearchData) => {}
});

export const UserProvider = ({ children, data }) => {
  const [user, setUser] = useState({
    ...data,
    searchData: data?.searchData || []
  });

  const updateSearchData = (newSearchData) => {
    setUser((prevData) => ({
      ...prevData,
      searchData: newSearchData
    }));
  };

  useEffect(() => {
    setUser((prevUser) => ({
      ...data,
      searchData: prevUser.searchData.length > 0 ? prevUser.searchData : data?.searchData ?? []
    }));
  }, [data]);

  const contextValue = useMemo(() => ({
    id: user?.id,
    edit_titles: user?.edit_titles,
    role: user?.role,
    move_titles_hubspot: user?.move_titles_hubspot,
    view_tables: user?.view_tables,
    searchData: user?.searchData,
    default_filters: user?.default_filters,
    updateSearchData
  }), [user, updateSearchData]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;

export const useUserPermit = () => {
  const context = useContext(UserContext);

  return context;
};

export default UserContext;