import { useInfiniteQuery } from 'react-query';
import { useActions } from '../../../../context/Actions.context';
import { useServices } from '../../../services/useServices';
import { IGetBookListResponseDto, IGetBookSearchResponseDto } from '../book.dto';

export const useGlobalSearch = (endpoint: string, searchQuery: string) => {
  const {
    book: { getBooksList }
  } = useServices();
  const { source: sourcePath } = useActions();

  return useInfiniteQuery<IGetBookSearchResponseDto | IGetBookListResponseDto, Error>(
    ['global-search-query', searchQuery],
    async ({ pageParam = 1 }) => getBooksList(endpoint, { page: pageParam, search: searchQuery }, sourcePath),
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.data.length === 100 ? allPages.length + 1 : undefined,
      enabled: Boolean(searchQuery.trim()),
      cacheTime: 0,
      staleTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true
    }
  );
};
