import React, { useState, useCallback } from 'react';
import { Form, AutoComplete, Input, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useActions } from '../../context/Actions.context';
import { useGlobalSearch } from '../../core/modules/book/hooks/useGlobalSearch';
import Endpoints, {
  ALL_TITLES_BST,
  BST_NAVIGABLE_PATHS
} from '../../core/constants/endpoints';
import OptionCard from './OptionCard';
import debounce from 'debounce';
import './styles.css';

const formatSearchResults = (data, source) =>
  data.map((book) => {
    const paths: { label: string; url: string }[] = [];

    if (book.current_tab && BST_NAVIGABLE_PATHS[book.current_tab]) {
      paths.push({
        label: book.current_tab,
        url: `${source}/${BST_NAVIGABLE_PATHS[book.current_tab]}?amazonasin=${book.ASIN}`
      });

      if (book.current_tab === 'Hot Titles') {
        paths.push({
          label: 'All New Titles',
          url: `${source}/${BST_NAVIGABLE_PATHS['All New Titles']}?amazonasin=${book.ASIN}`
        });
      }
    }

    return {
      key: book.ASIN,
      value: book.Title,
      label: <OptionCard book={book} paths={paths} />
    };
  });

const GlobalSearchInput = () => {
  const [form] = Form.useForm();
  const { source } = useActions();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hasSearched, setHasSearched] = useState(false);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useGlobalSearch(Endpoints[ALL_TITLES_BST], searchQuery);

  const options =
    data?.pages.flatMap((page) => formatSearchResults(page.data, source)) || [];

  const fetchResults = useCallback(
    debounce(async (query) => {
      setSearchQuery(query);
      setHasSearched(Boolean(query.trim()));
    }, 1500),
    []
  );

  const handleScroll = debounce((e) => {
    const { target } = e;

    if (
      hasNextPage &&
      !isFetchingNextPage &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight - 50
    ) {
      fetchNextPage();
    }
  }, 300);

  const autoCompleteOptions: {
    value: string;
    label: JSX.Element;
    disabled?: boolean;
  }[] = [...options];

  if (isLoading || isFetchingNextPage) {
    autoCompleteOptions.push({
      value: 'loading',
      label: <span style={{ color: '#888' }}>Loading...</span>,
      disabled: true
    });
  }

  if (hasSearched && !isLoading && options.length === 0) {
    autoCompleteOptions.push({
      value: '',
      label: <span style={{ color: '#888' }}>No Data Found</span>,
      disabled: true
    });
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 20px 0 auto'
      }}
    >
      <Form className='filter' form={form} initialValues={{ search: '' }}>
        <Form.Item name={'search'}>
          <AutoComplete
            className='global-filter'
            options={autoCompleteOptions}
            onSearch={(value) => fetchResults(value)}
            onPopupScroll={handleScroll}
          >
            <Input
              className='global-filter-input'
              placeholder='Search Bookstat Data...'
              prefix={<SearchOutlined />}
              suffix={
                isLoading || isFetchingNextPage ? (
                  <Spin
                    data-testid='loading-spinner'
                    size='small'
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                ) : null
              }
              allowClear
            />
          </AutoComplete>
        </Form.Item>
      </Form>
    </div>
  );
};

export default React.memo(GlobalSearchInput);
