import React, { useContext, useMemo, useState } from 'react';

export const defaultSort = {
  allnewtitles: { sortBy: 'Projected Sales', sortType: 'descending' },
  hotTitles: { sortBy: 'Sales Rank', sortType: 'ascending' },
  allexcluded: { sortBy: 'Projected Sales', sortType: 'descending' },
  bookstatdata: { sortBy: 'Projected Sales', sortType: 'descending' },
  manuallyadded: { sortBy: 'Projected Sales', sortType: 'descending' },
  competitors: { sortBy: 'Projected Sales', sortType: 'descending' },
  pendingexclusion: { sortBy: 'Projected Sales', sortType: 'descending' },
  podiumsales: { sortBy: '', sortType: '' },
  podiumtitles: { sortBy: 'Projected Sales', sortType: 'descending' },
  sendtocrm: { sortBy: 'Sent to CRM on date', sortType: 'descending' },
  watchlist: { sortBy: 'Added to Watchlist', sortType: 'descending' },
  contacted: { sortBy: 'Added to Contacted', sortType: 'descending' },
  popular: { sortBy: 'Views', sortType: 'descending' },
  trending: { sortBy: 'Views', sortType: 'descending' },
  risingStars: { sortBy: 'Views', sortType: 'descending' },
  authors: { sortBy: 'Followers', sortType: 'descending' },
  podiumAuthors: { sortBy: 'Followers', sortType: 'descending' },
  alltitlesRR: { sortBy: 'Views', sortType: 'descending' },
  watchlistRR: { sortBy: 'Added to Watchlist', sortType: 'descending' },
  contactedRR: { sortBy: 'Added to Contacted', sortType: 'descending' },
  senttocrmRR: { sortBy: 'Sent to CRM on date', sortType: 'descending' },
  pendingExclusionRR: { sortBy: 'Views', sortType: 'descending' },
  podiumtitlesRR: { sortBy: 'Views', sortType: 'descending' },
  excludedRR: { sortBy: 'Views', sortType: 'descending' },
  dailyRankEbooks: { sortBy: 'Series', sortType: 'descending' },
  dailyRankAudioBooks: { sortBy: 'Series', sortType: 'descending' },
  dailyRankCatalogue: { sortBy: 'Series', sortType: 'descending' }
};

const SortContext = React.createContext({
  sort: defaultSort,
  setSort: values => console.log(values)
});

export const SortProvider = ({ children, sort }) => {
  const [currentSort, setCurrentSort] = useState(sort || defaultSort);

  const saveSort = values => {
    setCurrentSort(values);
    return null;
  };

  const contextValue = useMemo(() => ({ sort: currentSort, setSort: saveSort }), [currentSort]);

  return (
    <SortContext.Provider value={contextValue}>
      {children}
    </SortContext.Provider>
  );
};

export const SortConsumer = SortContext.Consumer;

export const useSort = () => {
  const context = useContext(SortContext);

  return context;
};

export default SortContext;